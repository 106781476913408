export const palette = {
  purple: {
    900: '#1D0E44',
    800: '#2C1566',
    700: '#3A1B88',
    600: '#4922AA',
    500: '#5729CC',
    400: '#734DD4',
    300: '#8F70DD',
    200: '#AB94E5',
    100: '#C7B8EE',
    50: '#DDD4F5',
  },
  grey: {
    900: '#000000',
    800: '#1D1F22',
    700: '#363A40',
    600: '#616771',
    500: '#8E97A6',
    400: '#C4CAD3',
    300: '#E4E9F1',
    200: '#EBF0F7',
    100: '#F5F7FA',
    50: '#FBFBFD',
  },
  black: {
    900: '#020203',
    800: 'rgba(2, 2, 3, 0.8)',
    700: 'rgba(2, 2, 3, 0.7)',
    600: 'rgba(2, 2, 3, 0.6)',
    500: 'rgba(2, 2, 3, 0.5)',
    400: 'rgba(2, 2, 3, 0.4)',
    300: 'rgba(2, 2, 3, 0.3)',
    200: 'rgba(2, 2, 3, 0.2)',
    100: 'rgba(2, 2, 3, 0.15)',
    50: 'rgba(2, 2, 3, 0.1)',
  },
  white: {
    900: '#FFF',
    800: 'rgba(255, 255, 255, 0.8)',
    700: 'rgba(255, 255, 255, 0.7)',
    600: 'rgba(255, 255, 255, 0.6)',
    500: 'rgba(255, 255, 255, 0.5)',
    400: 'rgba(255, 255, 255, 0.4)',
    300: 'rgba(255, 255, 255, 0.3)',
    200: 'rgba(255, 255, 255, 0.2)',
    100: 'rgba(255, 255, 255, 0.15)',
    50: 'rgba(255, 255, 255, 0.1)',
  },
  lightBlue: {
    900: '#384155',
    800: '#546280',
    700: '#7082AA',
    600: '#8CA2D4',
    500: '#A8C3FF',
    400: '#B6CDFF',
    300: '#C5D7FF',
    200: '#D3E1FF',
    100: '#E2EBFF',
    50: '#EEF3FF',
  },
  lightTeal: {
    900: '#304B50',
    800: '#487078',
    700: '#60959F',
    600: '#78BBC7',
    500: '#90E0EF',
    400: '#A2E5F2',
    300: '#B5EAF4',
    200: '#C7EFF7',
    100: '#DAF5FA',
    50: '#E9F9FC',
  },
  lightOrange: {
    900: '#553B30',
    800: '#805949',
    700: '#AA7761',
    600: '#D49479',
    500: '#FFB291',
    400: '#FFBFA3',
    300: '#FFCCB6',
    200: '#FFD8C8',
    100: '#FFE5DA',
    50: '#FFF0E9',
  },
  lightYellow: {
    900: '#554D26',
    800: '#80743A',
    700: '#AA9A4D',
    600: '#D4C060',
    500: '#FFE773',
    400: '#FFEB8A',
    300: '#FFEFA2',
    200: '#FFF3B9',
    100: '#FFF7D0',
    50: '#FFFAE3',
  },
  red: {
    900: '#CA0019',
    800: '#D80C27',
    700: '#E51A2E',
    600: '#F72834',
    500: '#FF3334',
    400: '#FF4B51',
    300: '#F37075',
    200: '#F9999C',
    100: '#FFCDD4',
    50: '#FFEBEF',
  },
  orange: {
    900: '#DA5318',
    800: '#E56B1A',
    700: '#EC7A1C',
    600: '#F2891E',
    500: '#F79520',
    400: '#F8A435',
    300: '#FAB455',
    200: '#FBC984',
    100: '#FDDEB4',
    50: '#FEF2E1',
  },

  yellow: {
    900: '#E27900',
    800: '#E4A100',
    700: '#E5B907',
    600: '#E5D11A',
    500: '#E4E222',
    400: '#E8E749',
    300: '#EBEB6C',
    200: '#F1F196',
    100: '#F6F7C0',
    50: '#FBFCE5',
  },
  green: {
    900: '#008600',
    800: '#00A900',
    700: '#00BE00',
    600: '#00D40E',
    500: '#2EE51A',
    400: '#61EA4C',
    300: '#87F072',
    200: '#AEF59E',
    100: '#D0F9C5',
    50: '#ECFDE8',
  },
  blue: {
    900: '#081D4C',
    800: '#0C2B73',
    700: '#0F3999',
    600: '#1348BF',
    500: '#1756E5',
    400: '#3E72E9',
    300: '#648EEE',
    200: '#8BAAF2',
    100: '#B2C7F6',
    50: '#D1DDFA',
  },
} as const;
